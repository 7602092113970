@import '../css_variables/breakpoints';
@import '../css_variables/typography';
@import '../css_variables/color';
@import '../css_variables/util';

h1 {
  padding-top: 60px;
}

h2,
h3 {
  padding-top: 42px;
  margin-bottom: 14px;

  @include fontSizePxRems(20, $largeBasePixels);
  @include lineHeightPxRems(26, $largeBasePixels);
}

hr {
  margin: 42px auto 42px;
}

.page-not-found {
  background: #E7E7E7;

  .inner {
    background: transparent url('mason.jpg') bottom center no-repeat;
    padding-bottom: 200px;
  }

  form {
    background-color: #FFF;
    border: 1px solid #D2D2D2;
    margin: 0 0 14px;
    padding: 0;
    overflow: hidden;
    position: relative;
    max-width: 300px;
  }

  input {
    display: inline-block;
    width: 89%;
    border: none;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  button {
    display: inline-block;

    @include hide-text;

    background: url('../icons/search-gray.png') center no-repeat;
    cursor: pointer;
    box-shadow: none;
    padding: 0;
    margin: 0;
    height: 18px;
    width: 18px;
  }
}

.search-description {
  color: #666;
}

.who-is-sbf {
  color: #7D7D7D;
}

@media (min-width: $zero-minwidth) {
  h1 {
    padding-top: 20px;

    @include block-text-26($smallBasePixels);
  }

  hr {
    margin: 23px auto 23px;
  }

  h2,
  h3 {
    padding-top: 12px;
    margin-bottom: 10px;

    @include block-text-20($smallBasePixels);
  }

  .page-not-found {
    .inner {
      background-size: 320px;
      padding-bottom: 245px;
    }
  }
}

@media (min-width: $small-minwidth) {
  .page-not-found {
    .inner {
      background-size: 400px;
      padding-bottom: 315px;
    }

    form {
      margin: 0 auto 14px;
    }
  }
}

@media (min-width: $medium-minwidth) {
  h1 {
    padding-top: 60px;

    @include block-text-60($mediumBasePixels);
  }

  h2,
  h3 {
    margin-bottom: 14px;

    @include fontSizePxRems(21, $mediumBasePixels);
    @include lineHeightPxRems(26, $mediumBasePixels);
  }

  hr {
    margin: 42px auto 42px;
  }

  .page-not-found {
    form {
      margin: 0 0 14px;
    }
  }
}

@media (min-width: $large-minwidth) {
  .page-not-found {
    .inner {
      background-size: auto;
      padding-bottom: 200px;
    }
  }
}
